import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const DynamicContentRenderer = ({ content }) => {
    
    const parseContent = (text) => {
        const paragraphs = text?.split("\n\n"); 
        return paragraphs?.map((paragraph, index) => {
            const lines = paragraph?.split("\n"); 
            if (lines.length > 1) {
                return (
                    <List key={index} sx={{ paddingLeft: 3, marginBottom: 2 }}>
                        {lines?.map((line, i) => (
                            <ListItem key={i} sx={{ paddingLeft: 0 }}>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            sx={{ color: "#2C3E50", lineHeight: "1.6" }}
                                        >
                                            {line.trim()}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                );
            }
            return (
                <Typography
                    key={index}
                    variant="body1"
                    sx={{
                        marginBottom: 2,
                        color: "#4A4A4A",
                        lineHeight: "1.6",
                        fontFamily: "'Poppins', sans-serif",
                        textIndent: "1.5rem",
                    }}
                >
                    {paragraph.trim()}
                </Typography>
            );
        });
    };

    return (
        <Box
            sx={{
                padding: 2,
                // backgroundColor: "#f9f9f9",
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                // maxWidth: "800px",
               
            }}
        >
            {parseContent(content)}
        </Box>
    );
};

export default DynamicContentRenderer;
