import React from 'react'
import usePrescriptionStore from '../DataRepository/Prescription';
import { IconButton, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useEffect, useState, useRef } from 'react'
import aiwelllogo from '../Assets/images/Aiwell Logo 1 1.png'
import DownloadIcon from '@mui/icons-material/Download';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, TextField, Slider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = "";
            }
            return "";
        }
    };
};

function ViewPrescription() {

    const { selectedPrescription } = usePrescriptionStore()

    const [showExitPrompt, setShowExitPrompt] = useState(true);

    window.onload = function () {
        initBeforeUnLoad(showExitPrompt);
    };

    const audioRef = useRef(null);
    
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
        return () => {
            window.onbeforeunload = null;
        };
    }, [showExitPrompt]);

    const handleDownload = (s3Link) => {
        const link = document.createElement('a');
        link.href = s3Link;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const navigate = useNavigate()

    const ratings = ["Good", "Mild", "Moderate", "Poor", "Severe", "Extreme"];

    return <div className='Reports-main-page'>
        <div className='Reports-text-box'>
            <div className='flex flex-row justify-between item-center m-2'>
                <p>{selectedPrescription?.Date}</p>

                {selectedPrescription?.Audio_Prescription &&
                    <div>
                        <audio ref={audioRef}>
                            <source src={selectedPrescription?.Audio_Prescription} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                        <IconButton onClick={handlePlayPause} style={{ color: "#DA6E0B" }}>
                            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                        <p>Audio Prescription</p>
                    </div>
                }

            </div>

            <div className=''  >
                <div>
                    <div className="header">
                        <img
                            src={aiwelllogo}
                            alt="Aiwell Logo"
                        />
                        <div className="prescription-doctor-details">
                            <p>{selectedPrescription?.Doctor_details?.name} &nbsp;
                                <span style={{ fontSize: "12px" }}>{selectedPrescription?.Doctor_details?.course_details}</span></p>
                            <p style={{ fontSize: "12px" }}>
                                Register No: {selectedPrescription?.Doctor_details?.regno || 'Aa27999'}</p>
                            <p >{selectedPrescription?.Doctor_details?.speciality}</p>
                            <p >Ayurvedic Physician</p>
                        </div>
                    </div>

                    <div className="prescription-container">
                        <div>
                            <p className="Prescription-sh-h">Patient Details</p>
                            <div className="Patient-details">
                                <div>
                                    <p>Name:</p>
                                    <p>
                                        {selectedPrescription?.Patient_details?.firstname}{" "}
                                        {selectedPrescription?.Patient_details?.lastname}
                                    </p>
                                </div>

                                <div>
                                    <p>Age:</p>
                                    <p>{selectedPrescription?.Patient_details?.dob}</p>
                                </div>

                                <div>
                                    <p>Gender:</p>
                                    <p>{selectedPrescription?.Patient_details?.gender}</p>
                                </div>

                                {selectedPrescription?.Patient_details?.height && <div>
                                    <p>Height:</p>
                                    <p>{selectedPrescription?.Patient_details?.height}</p>
                                </div>}

                                {selectedPrescription?.Patient_details?.weight && <div>
                                    <p>Weight:</p>
                                    <p>{selectedPrescription?.Patient_details?.weight}</p>
                                </div>}

                                {selectedPrescription?.Patient_details?.bmi && <div>
                                    <p>BMI:</p>
                                    <p>{selectedPrescription?.Patient_details?.bmi}</p>
                                </div>}

                            </div>
                        </div>

                        <div>
                            <p className="Prescription-sh-h">Medical Details</p>
                            <div className="Medical-details-div">
                                <div>
                                    <p>Complaints:</p>
                                    <p>
                                        {selectedPrescription?.Medical_details?.Complaints}

                                    </p>
                                </div>
                                <div>
                                    <p>Examination / Lab findings:</p>
                                    <p>{selectedPrescription?.Medical_details?.lab}</p>
                                </div>
                                <div>
                                    <p>Medical History:</p>
                                    <p>{selectedPrescription?.Medical_details?.history}</p>
                                </div>
                                <div>
                                    <p>Diagnosis</p>
                                    <p>{selectedPrescription?.Medical_details?.diagnosis}</p>
                                </div>
                            </div>
                        </div>

                        {selectedPrescription?.VitalSigns &&
                            <div>
                                <p className="Prescription-sh-h">Vital Signs</p>
                                <div className="Medical-details-div">
                                    {Object.keys(selectedPrescription?.VitalSigns).map((e) => {
                                        return <div>
                                            <p>{e}</p>
                                            <p>
                                                {selectedPrescription?.VitalSigns[e]}
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        }

                        {selectedPrescription?.Goals &&
                            <div>
                                <p className="Prescription-sh-h">Goals Settings</p>
                                <div style={{ padding: '1rem' }}>
                                    <TableContainer>
                                        <Table aria-label="symptoms table">
                                            <TableHead style={{ backgroundColor: "#D4CEC3" }}>
                                                <TableRow>
                                                    <TableCell sx={{ width: '30%' }} align="center"
                                                        style={{
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: 500,
                                                            color: "#3B362A",
                                                            fontSize: "16px",
                                                            padding: "10px"
                                                        }}>Symptom</TableCell>
                                                    <TableCell sx={{ width: '60%' }} align="center" style={{
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontWeight: 500,
                                                        color: "#3B362A",
                                                        fontSize: "16px",
                                                        padding: "10px"
                                                    }}>Severity</TableCell>
                                                    <TableCell sx={{ width: '10%' }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ backgroundColor: "#F8F8F4" }}>
                                                {selectedPrescription?.Goals?.map((e, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell component="th" scope="row" sx={{ width: '30%' }}>
                                                            <p style={{
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: 500,
                                                                color: "#3B362A",
                                                                fontSize: "16px",
                                                            }}>{e?.symptoms}</p>
                                                        </TableCell>
                                                        <TableCell sx={{ width: '60%' }}>
                                                            <div className="flex items-center justify-center space-x-6">
                                                                {ratings?.map((label, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="flex flex-col items-center cursor-pointer"
                                                                    >
                                                                        <div
                                                                            className={`w-6 h-6 rounded-full transition-all duration-300 ${e?.severity >= index ? "bg-[#DA6E0B]" : "bg-gray-300"
                                                                                }`}
                                                                        ></div>
                                                                        <span className="mt-2 text-sm text-gray-700" style={{
                                                                            fontFamily: "'Poppins', sans-serif",
                                                                            fontWeight: 400,
                                                                        }}>{label}</span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: '10%' }}>
                                                            <p style={{
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: 500,
                                                                color: "#3B362A",
                                                                fontSize: "16px",
                                                            }}>{ratings[e?.severity]}</p>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        }

                        {
                            selectedPrescription?.labTests && <div>
                                <p className="Prescription-sh-h">Lab Tests</p>
                                <div className='labtestlist'>
                                    {
                                        selectedPrescription?.labTests.map((e, i) => {
                                            return <div className='labtestitem'>
                                                <p>{e}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }

                        {selectedPrescription?.Supplements &&
                            <div>
                                <p className="Prescription-sh-h">Medicines</p>
                                <div className='Supplement-medicine-card-container'>
                                    {selectedPrescription?.Supplements &&
                                        selectedPrescription?.Supplements.filter(e => e?.id !== '')?.map((e, i) => {
                                            return <div className='Supplement-medicine-card' onClick={() => {
                                                e.id != '' &&
                                                    navigate(`/Product?id=${e.id}`)
                                            }}>
                                                <div className='flex flex-row gap-10'>

                                                    <div className='Supplement-medicine-card-image'>
                                                        {e.id != '' ?
                                                            <img src={e?.imgurl} alt="Medicine Image" ></img>
                                                            :
                                                            <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Product+place+holder.png" alt="Medicine Image" />
                                                        }
                                                    </div>

                                                    <div className='Supplement-medicine-card-info'>
                                                        <p>{e.medicine}</p>

                                                        <div>
                                                            <p><span>Dose</span>: {e?.dose}</p>
                                                            <p><span>Order Quantity</span>: {e?.quantity}</p>
                                                            <p><span>Duration</span> : {e?.duration}</p>
                                                            <p><span>Instruction</span> : {e?.instruction}</p>
                                                        </div>

                                                    </div>

                                                </div>

                                                {e.id === '' && <p style={{ fontSize: "8px" }}>
                                                    This product is not available on our platform.</p>}

                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }

                        {selectedPrescription?.Supplements &&
                            selectedPrescription?.Supplements.some(e => e?.id === '') ?
                            <div>
                                <p className="Prescription-sh-h">Other Medicines</p>
                                <div className='Supplement-medicine-card-container'>
                                    {selectedPrescription?.Supplements &&
                                        selectedPrescription?.Supplements.filter(e => e?.id === '')?.map((e, i) => {
                                            return <div className='Supplement-medicine-card' onClick={() => {
                                                e.id != '' &&
                                                    navigate(`/Product?id=${e.id}`)
                                            }}>
                                                <div className='flex flex-row gap-10'>

                                                    <div className='Supplement-medicine-card-image'>
                                                        {e.id != '' ?
                                                            <img src={e?.imgurl} alt="Medicine Image" ></img>
                                                            :
                                                            <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Product+place+holder.png" alt="Medicine Image" />
                                                        }
                                                    </div>

                                                    <div className='Supplement-medicine-card-info'>
                                                        <p>{e.medicine}</p>

                                                        <div>
                                                            <p><span>Dose</span>: {e?.dose}</p>
                                                            <p><span>Order Quantity</span>: {e?.quantity}</p>
                                                            <p><span>Duration</span> : {e?.duration}</p>
                                                            <p><span>Instruction</span> : {e?.instruction}</p>
                                                        </div>

                                                    </div>

                                                </div>

                                                {e.id === '' && <p style={{ fontSize: "8px" }}>
                                                    This product is not available on our platform.</p>}

                                            </div>
                                        })
                                    }
                                </div>

                            </div> : <></>

                        }

                        {selectedPrescription?.Diet?.included?.length > 0 ||
                            selectedPrescription?.Diet?.avoided?.length > 0 ? (
                            <div className="after-page-break">
                                <p className="Prescription-sh-h">Diet</p>
                                <div className="container">

                                    {selectedPrescription?.Diet?.included?.length > 0 && (
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="icon">✅</div>
                                                <p>Food to be Included</p>
                                            </div>
                                            <div className="card-content">
                                                {selectedPrescription?.Diet?.included?.map(
                                                    (e, i) => (
                                                        <div key={i}>
                                                            <img
                                                                src={e?.imageLink}
                                                                alt={e?.englishName}
                                                            />
                                                            <p>{e?.englishName}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {selectedPrescription?.Diet?.avoided?.length > 0 && (
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="icon">❌</div>
                                                <p>Food to be Avoided</p>
                                            </div>
                                            <div className="card-content">
                                                {selectedPrescription?.Diet?.avoided?.map(
                                                    (e, i) => (
                                                        <div key={i}>
                                                            <img
                                                                src={e?.imageLink}
                                                                alt={e?.englishName}
                                                            />
                                                            <p>{e?.englishName}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        {selectedPrescription?.Recipes?.length > 0 && (
                            <div className="after-page-break">
                                <p className="Prescription-sh-h">Recipes</p>
                                <div className="item-container">
                                    {selectedPrescription?.Recipes?.map((e, i) => (
                                        <div key={i} className="item">
                                            <img
                                                src={e.imageLink}
                                                alt={e.recipe}
                                            />
                                            <p>{e.recipe}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {selectedPrescription?.Practices && (
                            <div className="after-page-break">
                                <p className="Prescription-sh-h">Lifestyle Practices</p>
                                <div className="lifestyle-container">
                                    {selectedPrescription?.Practices?.yoga?.length > 0 && (
                                        <div className="lifestyle-card">
                                            <div className="lifestyle-card-header">Yoga</div>
                                            <div className="lifestyle-card-content">
                                                <ul>
                                                    {selectedPrescription?.Practices?.yoga?.map((e, i) => (
                                                        <li key={i} onClick={() => {
                                                            navigate(`/Yoga?id=${e?.id}`)
                                                        }}>

                                                            {e.title}

                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {selectedPrescription?.Practices?.meditation?.length > 0 && (
                                        <div className="lifestyle-card">
                                            <div className="lifestyle-card-header">Meditation</div>
                                            <div className="lifestyle-card-content">
                                                <ul>
                                                    {selectedPrescription?.Practices?.meditation?.map((e, i) => (
                                                        <li key={i} onClick={() => {
                                                            navigate(`/Meditation?id=${e?.Id}`)
                                                        }}>

                                                            {e.title}

                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {selectedPrescription?.Practices?.pranayama?.length > 0 && (
                                        <div className="lifestyle-card">
                                            <div className="lifestyle-card-header">Pranayama</div>
                                            <div className="lifestyle-card-content">
                                                <ul>
                                                    {selectedPrescription?.Practices?.pranayama?.map((e, i) => (
                                                        <li key={i} onClick={() => {
                                                            navigate(`/Pranayama?id=${e?.id}`)
                                                        }}>
                                                            {e.title}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {selectedPrescription?.LifeStyle_changes?.length > 0 && (
                            <div className="after-page-break">
                                <p className="Prescription-sh-h">LifeStyle Changes</p>
                                <div className="LifeStyle-changes-Container">
                                    <ul>
                                        {selectedPrescription?.LifeStyle_changes.map((e, i) => (
                                            <li key={i}>{e}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <div className='flex flex-row items-center justify-center mt-10 mb-10'>
                            <div className='prescription-buy-now'>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        backgroundColor: '#DA6E0B',
                                        color: 'white',
                                        border: 'none',
                                        boxSizing: 'border-box',
                                        borderRadius: '5px',
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: '10px'
                                    }}

                                    onClick={() => handleDownload(selectedPrescription?.Prescription)}
                                >
                                    <DownloadIcon />&nbsp; Download Prescription
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default ViewPrescription